import React from 'react';
import { css } from '@emotion/react';
import { wrapper, IconButtonBtn, IconButton } from '../defaults/styles/elements';
import mq from '../defaults/styles/mediaquerys';
import { color } from '../defaults/styles/theme';
import Link from '../link';
import Info from '../defaults/assets/svg/info.svg'
import { useRegistration } from '../../services/typeformRegistration';
import Flags from '../../data/raw/flags'

let dates = [
  {
    day: 21,
    month: "July",
    url: "/agenda/day1",
  },
  {
    day: 22,
    month: "July",
    url: "/agenda/day2"
  },
  {
    day: 23,
    month: "July",
    url: "/agenda/day3"
  }
]

const DateEl = ({day, month, url, className, mobileMonth}) => {

  const Suspension = ({className}) => {
    let i = 0;
    let obj = []

    while (i < 2) {
      i++;
      obj.push(
        <div className={className} key={i} css={css`
          height: 1em;
          position: absolute;
          width: .35em;
          background: ${color.richBlack};
          top: 0;
          &:before {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 0;
            background: ${color.plue100};
            width: 100%;
            height: 1em;
          }
          &:nth-of-type(even) {
            right: 20%;
          }
          &:nth-of-type(odd) {
            left: 20%;
          }
        `} />
       )
    }

    return obj;
  }

  const CalendarObj = ({day, month, className, url, mobileMonth}) => {
    return (
      <div className={className} css={css`
        display: flex;
        background: ${color.plue100};
        width: 100%;
        max-width: 17rem;
        border-radius: 5px;
        position: relative;
        margin-top: .3em;
        align-items: center;
        justify-content: center;
        flex: 0 1 auto;
        ${mq[0]} {
          height: 8rem;
          width: 8rem;
          margin-top: 1em;
        }
      `}>
        <Suspension css={css`
          display: ${mobileMonth ? "block" : "none" };
          ${mq[0]} {
            display: block;
          }
        `}/>
        <div css={css`
          display: flex;
          align-items: center;
          flex-direction: column;
          padding: 1.25em 0;
          p {
            font-weight: 700;
            letter-spacing: 0.03em;
            color: ${color.richBlack};
            text-transform: uppercase;
            line-height: 1em;
          }
        `}>
          <p css={css`
            font-size: .75em;
            margin-bottom: ${mobileMonth ? 0 : "1em"};
            margin-top: ${mobileMonth ? "2em" : 0};
            display: ${mobileMonth ? "block" : "none"};
            ${mq[0]} {
              display: block;
            }
          `}>{ month }</p>
          <p css={css`
            font-size: 2em;
            margin-bottom: 0;
            ${mq[0]} {
              font-size: 3em;
            }
          `}>{ day }</p>
        </div>
        {url && !mobileMonth && <p css={css`
            position: absolute;
            bottom: .4em;
            width: 100%;
            text-align: center;
            opacity: .5;
            margin: .2em 0 0 0;
            color: ${color.main_dark};
            font-size: .55em;
            margin-bottom: 0;
          `}>click to find out more
        </p>}
      </div>
    )
  }


  if (url && !mobileMonth) {
    return (
      <Link to={url} css={css`
        text-decoration: none;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 17em;
        ${mq[0]} {
          width: auto;
        }
      `}>
        <CalendarObj day={day} month={month} className={className} url={url} />
      </Link>
    )
  } else {
      return (
        <CalendarObj className={className} day={day} month={month} mobileMonth={mobileMonth} />
      )
  }
}

const Introduction = () => {
  const {registerButton, toggle} = useRegistration()
  return (
    <section css={css`
      background: ${color.richBlack};
      padding: 10rem 0;
    `}>
      <div css={[wrapper, css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `]}>
        <h2 css={css`
          font-weight: 600;
          font-size: 1.625em;
          letter-spacing: 0.01em;
          line-height: 1.4062em;
          color: ${color.main_light};
          text-align: center;
          max-width: 40rem;
          margin: 0 auto;
          width: 85%;
          ${mq[0]} {
            font-size: 1.6em;
          }
          ${mq[1]} {
            font-size: 1.8em;
          }
          ${mq[2]} {
            font-size: 2.625em;
          }
        `}>We don't hesitate to innovate.</h2>

        { dates && <div css={css`
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: center;
          max-width: ${dates.length * 8 + 8 + "em"};
          width: 100%;
          margin: 5em auto;
          ${mq[0]} {
            flex-direction: row; 
            align-items: flex-start;
          }
        `}>
          {dates.every(date => date.month === dates[0].month) ? (
            <DateEl mobileMonth month={dates[0].month} css={css`
              ${mq[0]} {
                display: none;
              }
            `} />
          ) : console.error("not all months of the dates array are identical. Please check array.")}
          {dates.map((date, index) => (
            <DateEl day={date.day} month={date.month} url={date.url} key={index} />
          ))}
        </div> }

        <div css={css`
          max-width: 42rem;
          width: 90%;
          margin: 0 auto;
          p {
            color: ${color.main_light};
            font-size: 1em;
            font-weight: 500;
            line-height: 1.7em;
            text-align: center;
            &:last-of-type {
              margin-bottom: 0;
            }
            ${mq[0]} {
              font-size: 1.1em;
            }
            ${mq[1]} {
              font-size: 1.2em;
            }
            ${mq[2]} {
              font-size: 1.2em;
            }
          }
        `}>
          <p>
          For our 5-year anniversary, we’ve created something unique. Join us for three days of innovation, technology, and discovery on July 21, 22 and 23 – virtual!</p><p>STARTUP AUTOBAHN powered by Plug and Play's EXPO 10 welcomes the brightest minds and top innovators to showcase their technologies – in the field of mobility, production, enterprise, sustainability, and beyond – to industry leaders and curious creators around the world. Our schedule is full of fascinating speakers, startups, tech, and a great deal of networking.
          </p>
        </div>
        
        {Flags.features.live ? (
          <>
            <IconButton
              to={Flags.settings.live.destination}
              name="Join the live event"
              css={css`
                font-size: 1.5em;
                letter-spacing: .01em;
                font-weight: 600;
                padding: .3em 2em;
                margin-top: 5rem;
                &:before {
                  background: ${color.plue_rec};
                }
              `}
            />
            <p css={css`
              margin-top: 1em;
              margin-bottom: -2rem;
              color: white;
              font-size: .85em;
            `}>or</p>
          </>
        ) : null}

        {Flags.features.main_registration ? (
          <>
            <IconButtonBtn
              onClick={toggle}
              name={registerButton.buttonText} 
              css={css`
                font-size: 1.5em;
                letter-spacing: .01em;
                font-weight: 600;
                padding: .3em 2em;
                margin-top: 2em;
                &:before {
                  background: none;
                  border: 2px solid ${color.main_light};
                }
              `}
            />
            {registerButton.errorMessage && <div id="registerErrorMessage" css={css`
              background: ${color.plue100};
              padding: 1em;
              scroll-margin-top: 15vh;
              font-size: .65em;
              max-width: 35em;
              margin-top: 1.5em;
              line-height: 1.4em;
              border-radius: 5px;
              display: flex;
            `}>
              <div css={css`
                opacity: .5;
                fill: ${color.main_dark};
                width: 5em;
                svg {
                  height: auto;
                  width: 70%;
                }
              `}><Info /></div>
              <div>
                <p css={css`
                  color: ${color.main_dark};
                  opacity: .5;
                  margin-bottom: 0;
                `}>{registerButton.errorMessage}</p>
              </div>
            </div>}
          </>
        ) : null}

      </div>
    </section>
  )
  
}

export default Introduction;